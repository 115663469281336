@import '../../../assets/styles/fonts.scss';
@import '../../../assets/styles/palette.scss';

.Product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;

    width: 100%;

    cursor: pointer;
    box-shadow: 0 1px 3px 0 #e6ebf1;

    &:hover {
        opacity: 0.6;
    }

    &-banner {
        @include sans-serif($font-size: 14px, $color: white);

        position: absolute;
        top: 24px;
        right: 0;

        padding: 4px 12px;

        background-color: white;
        border: 1px solid #BBBBBB;
        color: #BBBBBB;
    }
    &-cover {
        width: 100%;
        height: 300px;

        object-fit: cover;
    }
    &-footer {
        text-align: center;
        margin-bottom: 16px;

        :first-child {
            margin-top: 16px;
            @include sans-serif($font-size: 16px);
        }
        :last-child {
            margin-top: 4px;
            @include sans-serif($font-size: 12px);
        }
    }

    em {
        text-decoration: line-through;
    }
}
