.Login {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-banner {
        width: 400px;
        margin: auto;
        margin-bottom: 25%;
    }

    &-form {
        align-self: center;
        padding: 24px !important;
        margin-top: 25%;

        text-align: center;

        background: #fff;
        box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.23);

        &-button {
            text-transform: uppercase;
        }
    }
}