@import './palette.scss';

$font-family-stack: 'Roboto Condensed', Helvetica, Arial, sans-serif;

$font-thin: 100;
$font-regular: 400;
$font-bold: 700;

@mixin sans-serif($font-weight: $font-regular, $font-size: 14px, $color: $content, $letter-spacing: 0.3px, $text-transform: none) {
    font-family: $font-family-stack;
    font-weight: $font-weight;
    font-size: $font-size;
    color: $color;
    letter-spacing: $letter-spacing;
    text-transform: $text-transform;
    line-height: normal;
}