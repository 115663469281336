@import '../../assets/styles/fonts.scss';

.Footer {
    width: 100%;
    height: 110px;

    padding-top: 24px;
    padding-bottom: 16px;
    text-align: center;

    @include sans-serif();

    &-ads {
        @include sans-serif($font-size: 26px);
        margin-bottom: 8px;

        &:hover {
            opacity: 0.7;
        }
    }
}