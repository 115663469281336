@import '../../assets/styles/palette.scss';

.Checkout {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;

    &-stepper {
        text-transform: capitalize;
        margin: 24px auto;
        max-width: 512px;

        .ant-steps-item-active ,
        .ant-steps-item-finish{
            .ant-steps-item-icon {
                background-color: white !important;
                border-color: $content !important;
            }
            .ant-steps-icon {
                color: $content !important;
            }
        }

        .ant-steps-item-finish {
            .ant-steps-item-title::after {
                background-color: $content !important;
            }
        }

    }
}