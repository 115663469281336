@import '../../assets/styles/fonts.scss';

.Cart {
    button:hover {
        opacity: 0.7;
    }

    em {
        text-decoration: line-through;
    }

    &-item {
        display: flex;
        flex-direction: row;
        height: 120px;

        @include sans-serif($font-size: 18px);

        &.mod-mini {
            height: 65px;
            @include sans-serif($font-size: 14px);

            .Cart-item-thumbnail {
                width: 60px;
            }
        }

        &-thumbnail {
            display: inline;
            width: 120px;
            height: 100%;

            object-fit: contain;
        }
        &-name {
            align-self: center;
            margin-left: 42px;
        }
    }
    .ant-table-cell {
        vertical-align: middle;
    }
}
@media (max-width: 640px) {
    .Cart {
        &-item{
            @include sans-serif($font-size: 12px);
            height: 50px;
            &-name {
                margin-left: 4px;
            }
            &-thumbnail {
                width: 40px;
                height: 100%;
            }
        }
    }
}