@import '../../assets/styles/fonts.scss';
@import '../../assets/styles/palette.scss';

.Header {
    @include sans-serif();
    padding-bottom: 32px;


    overflow: hidden;

    &-nav {
        display: flex;
        justify-content: space-between;
        line-height: 24px;
        padding-top: 16px;
    }
    &-maintenance {
        white-space: pre-line;
        margin-right: 90px;
    }

    &-banner {
        width: auto;
        height: 80px;
        margin-top: 16px;
        opacity: 0.7;
    }

    &-back,
    &-cart {
        &:hover {
            opacity: 0.7;
        }
    }

    &-cart {
        &-container {
            width: 100%;
            max-width: 1048px;

            margin: auto;
            pointer-events: none;

            position: fixed;
            top: 127px;

            overflow: hidden;
            z-index: 1;
        }

        display: block;
        height: 24px;
        width: 125px;

        padding-left: 8px;
        padding-right: 38px;
        margin-right: -30px;
        margin-left: auto;

        pointer-events: visible;

        color: white;
        background-color: $content;
        border-radius: 24px;

        line-height: 24px;

        &:hover {
            color: white;
        }
    }

    .mod-hide {
        visibility: hidden;
    }
}
@media (max-width: 640px) {
    .Header {
        &-nav {
            padding-top: 8px;
            padding-bottom: 16px;

        }
        &-cart {
            position: fixed;
            top: 79px;
            right: 0px;
        }
        &-banner {
            height: 40px;
        }
    }
}