.Input {
    & > input {
        width: 100%;
        height: 20px;

        outline: none;
        border: none;
        border-radius: 5px;
        background-image:none;
        background-color: white;
        box-shadow: 0 1px 3px 0 #e6ebf1;

        padding: 24px 8px;

        &[type='number'] {
            text-align: center;
        }
    }
    & > textarea {
        width: 100%;
        resize: none;

        outline: none;
        padding: 4px 8px;
        border: none;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0 1px 3px 0 #e6ebf1;

        &:focus {
            border-color: #40a9ff;
            border-right-width: 1px;
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        }
    }

    &-content {
        height: 72px;
    }


}