.Store {
    width: 100% !important;
    height: 100% !important;

    position: absolute;
    top: 0;
    left: 0;

    &-layout {
        height: 100vh;
        overflow: scroll;
    }

    .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}