@import '../../../assets/styles/fonts.scss';

.CreateProduct {
    width: 300px;
    height: 400px;
    margin: 4px;

    cursor: pointer;

    &-textarea {
        height: 120px;
        margin-top: 8px;
    }

    &-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 48px;
        input {
            width: 180px;
            margin-right: 8px;
        }
        select {
            width: 116px;

            outline: none;
            border: none;
            border-radius: 5px;
            background-image:none;
            background-color: white;
            box-shadow: 0 1px 3px 0 #e6ebf1;
            line-height: 20px;
        }
    }

    .ant-card {
        &-cover {
            height: 150px;

            img {
                height: 100%;
                object-fit: cover;
            }
        }
        &-body {
            padding: 12px;
            height: 200px;

            & > * {
                margin-bottom: 4px;
            }
        }
        &-actions {
            height: 50px;

            li {
                margin: 0;
                @include sans-serif($font-size: 14px);
                height: 30px;

                input[type='submit'] {

                    cursor: pointer;
                    outline: none;

                    border: none;
                    margin: 0;
                    padding: 0;
                    width: auto;
                    overflow: visible;

                    background: transparent;
                    height: 48px;

                    color: inherit;
                    font: inherit;
                }
            }
        }
    }
}