@import '../../../assets/styles/palette.scss';

.Information {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &-user {
        flex: 1;
        margin-right: 16px;

        &-address {

            Input,
            select {
                margin: 8px 0;
            }

            a {
                text-decoration: underline;
            }
        }
    }
    &-cart {
        flex: 1;
    }

    &-checkbox {
        margin-left: 4px;
    }

    &-total {
        margin-top: 24px;
        text-align: right;
    }

    &-submit {
        cursor: pointer;

        width: 100%;

        border: 2px solid $content;
        outline: none;

        background-color: white;
        color: $content;
        text-align: center;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 18px;
        padding: 16px 32px;

        margin-top: 16px;

        &:focus {
            text-decoration: none;
        }
        &:hover {
            background-color: $content;
            color: white;
        }
    }
    &-error {
        color: #ff4d4f;
    }
}


@media (max-width: 640px) {
    .Information {
        flex-direction: column;
        &-user {
            margin-right: 0;
            margin-bottom: 48px;
        }
    }
}