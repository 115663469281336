@import '../../assets/styles/palette.scss';

.CheckoutForm {

    &-submit {
        cursor: pointer;

        width: 100%;

        border: 2px solid $content;
        outline: none;

        background-color: white;
        color: $content;
        text-align: center;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 18px;
        padding: 16px 32px;

        margin-top: 24px;

        &:focus {
            text-decoration: none;
        }
        &:hover {
            background-color: $content;
            color: white;
        }

        &:disabled {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }
}