.Commands {
    &-filters {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        width: 440px;
    }

    &-modal {
        &-addresses {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            :first-child {
                margin-right: 32px;
            }

            h2 {
                font-size: 18px;
            }
        }

        &-total {
            text-align: right;
        }

        &-invoice {
            position: absolute;
            top: -16px;
            right: -16px;

            text-align: center;
            line-height: 32px;

            width: 32px;
            height: 32px;
            border-radius: 50%;
            box-shadow: 0 1px 3px 0 #e6ebf1;

            background-color: white;

            &:hover {
                background-color: #C6C6C6;
            }
        }
    }

    &-list {
        .ant-table {
            height: calc(100vh - 160px);
            overflow-y: scroll;
        }
        .ant-table-cell {
            padding: 4px 8px;
        }
    }

}