@import '../../../assets/styles/fonts.scss';

.AdminProduct {
    width: 300px;
    height: 400px;


    margin: 4px;
    @include sans-serif($font-size: 18px);

    &-promo {
        &::after {
            position: absolute;
            top: 0;
            right: 20px;

            display: block;
            height: 100%;

            content: ' %';
            line-height: 50px;
        }
    }
    &-label {
        font-size: 8px !important;
        position: absolute;
        top: 4px;
        left: 4px;

        line-height: 8px;
    }

    .ant-card-cover {
        text-align: center;
        overflow-y: auto;

        height: 150px;

        .ant-btn-icon-only {
             border-radius: 50%;
        }

        .ant-upload {
            &-list {
                &-item {
                    float: left;
                    margin: 2px;
                    position: relative;

                    &-name {
                        display: none;
                    }
                    &-card-actions {
                        position: absolute;
                        top: -5px;
                        right: -5px;

                        background-color: white;
                        border-radius: 50%;
                        opacity: 0.7;
                        font-size: 7px;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
                &-picture {
                    width: 100%;
                    height: calc(100% - 32px);
                }
            }

            &-select {
                position: absolute;
                top: -6px;
                right: -14px;
            }
        }
    }
    .ant-card {
        &-body {
            padding: 12px;
            height: 200px;
            overflow-y: scroll;
        }
    }
    .ant-card-actions {
        li {
            margin: 0;
            @include sans-serif($font-size: 14px);


            & > * {
                line-height: 48px;
            }
        }

        li:first-child {
            position: relative;

            &::after {
                position: absolute;
                top: 0;
                right: 20px;

                display: block;
                height: 100%;

                content: ' €';
                line-height: 50px;
            }
        }

    }
}