
@import '../../assets/styles/fonts.scss';
@import '../../assets/styles/palette.scss';

.DetailProduct {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    width: 100%;

    &-images {
        width: 66%;
        height: 100%;
        margin-right: 12px;

    }
    &-desc {
        @include sans-serif($font-weight: $font-thin);

        width: 33%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-self: flex-start;

        white-space: pre-line;
        white-space: pre-wrap;

        &-story {
            opacity: 0.6;
        }

        &-banner {
            display: inline-block;
            margin-top: 4px;
            padding: 4px 12px;

            background-color: white;
            border: 1px solid #BBBBBB;
            color: #BBBBBB;
        }

        &-price {
            margin: 16px 0;
            font-size: 16px;

            em {
                text-decoration: line-through;
            }
        }
        input[type='number'] {
            width: 60px;
            text-align: center;
            border: none;
            margin-left: 4px;
        }

        &-logos {
            vertical-align: bottom;
            width: 45%;
            opacity: 0.7;

            & > img {
                max-width: 33%;
            }
        }

        &-purchase {
            margin-top: 24px;

            &-quantity {
                margin-bottom: 18px;
            }
        }
    }
}

@media (max-width: 960px) {
    .DetailProduct {
        &-images,
        &-desc {
            width: 50%;
        }
    }
}
@media (max-width: 640px) {
    .DetailProduct {
        height: 100%;
        flex-direction: column;

        &-images,
        &-desc {
            width: 100%;
            margin-right: 0;

            &-logos {
                width: 50%;
            }

            &-purchase {
                text-align: center;
            }
        }

    }
}