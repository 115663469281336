
@import '../../../assets/styles/fonts.scss';
@import '../../../assets/styles/palette.scss';

.Thanks {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;

    &-contact {
        margin-top: 16px;
        & > a {
            text-decoration: underline;
            &:hover {
                text-decoration: underline;
            }
        }
}

    &-button {
        display: block;
        max-width: 300px;

        margin: 24px 0;

        cursor: pointer;

        border: 2px solid $content;
        outline: none;

        background-color: white;
        color: $content;
        text-align: center;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 18px;
        padding: 16px 32px;


        &:focus {
            text-decoration: none;
        }
        &:hover {
            background-color: $content;
            color: white;
        }
    }


    &-content {
        flex: 1;
        margin-right: 16px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-map {
            height: 250px;
            margin-bottom: 16px;
        }
    }
    &-cart {
        flex: 1;
    }
}

@media (max-width: 640px) {
    .Thanks {
        flex-direction: column;
        &-contact {
            margin-top: 16px;
        }
        &-content {
            margin-right: 0;
            margin-bottom: 48px;
        }
    }
}