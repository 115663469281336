@import '../../assets/styles/palette.scss';

.Button {
    display: inline-block;
    cursor: pointer;

    border: 2px solid $content;
    text-align: center;
    text-transform: uppercase;
    background-color: white;
    color: $content;
    font-size: 16px;
    outline: none;

    &:focus {
        text-decoration: none;
    }
    &:hover {
        background-color: $content;
        color: white;
    }
    &:active {
        opacity: 0.6;
    }

    &.disabled, &:disabled {
        cursor: not-allowed;

        background-color: transparent;
        border: solid 1px grey;

        color: lightgray;
    }

    &.mod-primary {
        &.disabled, &:disabled {
            color: $content;
            opacity: 0.44;
        }
    }

    // Button size
    &.size{
        &-small {
            padding: 4px 12px;

            font-size: 12px;
            line-height: 12px;
        }
        &-medium {
            line-height: 18px;
            padding: 8px 20px;
        }
        &-large {
            line-height: 18px;
            padding: 16px 24px;
        }
    }

    & > *:not(:first-child) {
        margin-left: 10px;
    }
}
