@import '../../../assets/styles/fonts.scss';

.Payment {
    display: flex;
    flex-direction: row;

    &-error {
        color: #ff4d4f;
    }
    &-info {
        flex: 2;
        margin-right: 16px;

        &-addresses {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            @include sans-serif($font-size: 16px);

            padding: 16px 0;
            div {
                flex: 1;
                margin-right: 4px;
            }

            & > p {
                margin-top: 4px;
            }
        }
    }

    &-command {
        flex: 2;

        &-total {
            margin-top: 24px;
            text-align: right;
        }
    }

}

@media (max-width: 640px) {
    .Payment {
        flex-direction: column;
        &-info {
            margin-right: 0;
            margin-bottom: 48px;
        }
    }
}