@import '../assets/styles/fonts.scss';

.App {
    width: 100%;

    padding: 0 8px;
    background-color: white !important;
    margin: auto;

    @include sans-serif();

    h1 {
        @include sans-serif($font-size: 30px);
        padding-bottom: 8px;
    }
    h2 {
        @include sans-serif($font-size: 20px);
    }
    h3 {
        @include sans-serif($font-size: 18px);
    }

    &-content {
        width: 100%;
        min-height: 100vh;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 1048px;
        margin: auto;

        &-body {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            min-height: calc(100vh - 115px);
        }
    }
}