@import '../../assets/styles/fonts.scss';

.Products {
    h1 {
        padding: 8px 0;
    }
    &-filters {
        display: flex;
        justify-content: center;
        padding: 18px 0;

        text-align: center;

        @include sans-serif($font-size: 12px);

        span {
            width: 60px;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
                text-decoration: underline;
            }

            &.mod-active {
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }

    &-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}