@import '../../assets/styles/fonts.scss';

.Shop {

    &-filters {
        display: flex;
        justify-content: space-between;
        width: 200px;
        margin: auto;

        text-align: center;
        margin-bottom: 16px;

        @include sans-serif($font-size: 12px);

        span {
            cursor: pointer;

            &:hover {
                opacity: 0.6;
                text-decoration: underline;
            }

            &.mod-active {
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }

    &-market {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        &-product {
            width: calc(100% / 3);
            padding: 8px;
        }
    }

}
@media (min-width: 961px) {
    .Shop {
        &-market-product {
            &:nth-child(3n) {
                padding-right: 0;
            }
            &:nth-child(3n+1) {
                padding-left: 0;
            }
        }
    }
}
@media (max-width: 960px) {
    .Shop {
        &-market-product {
            width: calc(100% / 2);

            &:nth-child(2n) {
                padding-right: 0;
            }
            &:nth-child(2n+1) {
                padding-left: 0;
            }
        }
    }
}
@media (max-width: 640px) {
    .Shop {
        &-market-product {
            width: 100%;
            padding: 0;
            margin: 4px;
        }
    }
}