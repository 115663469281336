@import '../../assets/styles/palette.scss';
$thumbnailsSize: 65px;

.Carousel {
    width: 100%;

    display: flex;
    flex-direction: row-reverse;

    &-thumbnails {
        width: $thumbnailsSize;
        overflow: hidden;
        margin-right: 4px;

        &-item {
            width: $thumbnailsSize;
            height: $thumbnailsSize;
            object-fit: contain;
            box-sizing: content-box;
            cursor: pointer;

            &.mod-selected {
                border: 1px solid $content;
                box-sizing: border-box;
            }
        }
    }

    &-content {
        width: calc(100% - #{$thumbnailsSize} - 4px);

        &-image {
            object-fit: contain;
            background-color: white;
        }
    }
}

@media (max-width: 640px) {
    .Carousel {
        flex-direction: column;
        &-thumbnails {
            width: 100%;
            height: $thumbnailsSize;
            &-item {
                max-width: 0 4px;
                margin-right: 2px;
            }
        }
        &-content {
            width: 100%;
        }
    }
}

@media (max-height: 640px) {
    .Carousel {
        &-content {
            &-image {
                height: 300px;
            }
        }
    }
}