@import '../../assets/styles/fonts.scss';
@import '../../assets/styles/palette.scss';

.Total {
    @include sans-serif($font-size: 16px);
    line-height: 1.5;

    & > p {
        opacity: 0.6;
    }

    &-sum {
        @include sans-serif($font-size: 24px);
        line-height: 2;
        color: $content;
        opacity: 1 !important;
    }
}