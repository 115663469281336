.Validation {
    &-total {
        margin-top: 24px;
        text-align: right;
    }

    &-checkout {
        margin-top: 24px;
        text-align: right;
    }
}


@media (max-width: 960px) {
    .Validation {
        &-total,
        &-checkout {
            text-align: center;
        }
    }
}