@import '../../assets/styles/fonts.scss';

.Home {
    display: flex;
    justify-content: center;
    flex-direction: column;

    position: relative;

    flex: 1;

    &-text {
        @include sans-serif($font-size: 16px);

        position: absolute;
        top: -80px;
        left: 0;
    }

    &-sections {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        width: 100%;
        text-align: center;

        div {
            margin: 8px;
            cursor: pointer;

            &:hover {
                opacity: 0.6;
            }
        }

        img {
            border-radius: 50%;
            width: 100%;
        }
    }
}

@media (max-width: 640px) {
    .Home {
        height: auto;

        &-text {
            @include sans-serif($font-size: 14px);
        }

        &-sections {
            flex-direction: column;
            justify-content: center;

            div {
                margin-bottom: 16px;
            }
            img {
                width: 50%;
            }
        }
    }
}
